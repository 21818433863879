import React from "react"
import { OgTags } from "../components/OpenGraph";
import Header from "../components/Header"
import TitleBanner from "../components/TitleBanner"
import ServiceCard from "../components/ServiceCard"
import Brands from "../components/Brands"
import Testimonials from "../components/Testimonials"
import ContentImage from "../components/ContentImage"
import Footer from "../components/Footer"

const services = [
  {
    image: require('../assets/images/mascot-development.png'),
    title: 'Development',
    subtitle: 'Web & app development',
    linkText: 'Learn more',
    linkPath: '/development'
  }, {
    image: require('../assets/images/mascot-design.png'),
    title: 'Design',
    subtitle: 'Web & graphics design',
    linkText: 'Learn more',
    linkPath: '/design'
  }, {
    image: require('../assets/images/mascot-maintenance.png'),
    title: 'Support',
    subtitle: 'Proactive maintenance',
    linkText: 'Learn more',
    linkPath: '/support'
  }
]

const logo = [
  {
    name: 'Accenture',
    image: require('../assets/images/brands-accenture.png'),
    link: 'https://www.accenture.com/'
  }, {
    name: 'Plotly',
    image: require('../assets/svg/brands-plotly.svg'),
    link: 'https://plotly.com/'
  }, {
    name: 'King Kong',
    image: require('../assets/images/brands-kingkong.png'),
    link: 'https://kingkong.com.au/'
  }, {
    name: 'CraftersCompanion',
    image: require('../assets/images/brands-crafters-companion.png'),
    link: 'https://www.crafterscompanion.com/'
  }, {
    name: 'Holcim',
    image: require('../assets/svg/brands-holcim.svg'),
    link: 'https://www.holcim.com/'
  }
]

const testimonyList = [
  {
    text: "Wow. U guys are simply amazing. This design is stunnning. Your design team rocks @Peng! They nailed it first go. Quite impressive",
    image: require('../assets/images/eddie-machaalani.png'),
    name: 'Eddie Machaalani',
    position: 'BigCommerce, Co-founder'
  },
  {
    text: `I was incredibly impressed by how you matched specs with clean code. And the team is super easy to work with!`,
    image: require('../assets/images/mohammed-abid.png'),
    name: 'Mohammed Abid',
    position: 'Facebook, Product Designer'
  }, {
    text: `Wanted to say I love my 2 sites. They are great. The site gets a ton of conversions. I'd say at least half my phone calls are form the website call now buttons.`,
    image: require('../assets/images/troy-parsons.png'),
    name: 'Troy Parsons',
    position: 'AAR Auto Glass, Owner'
  }
]

const cta = {
  hasBackground: true,
  hasButton: true,
  styleB: true,
  title: 'Need some help?',
  desc: `We know trying a new service can be scary, that's why we want to make this 100% risk-free for you and your company.`,
  image: require('../assets/images/mascot-cta.png'),
  buttons: [
    {
      type: 'normal',
      text: 'Book a call',
      link: 'https://calendly.com/fullstackhq/meeting',
      beacon: false,
      external: true
    }, {
      type: 'outline',
      text: 'Chat with us',
      link: '/',
      beacon: true
    }
  ]
}

function index() {
  return (
    <>
      <OgTags
        ogTitle="Fullstack HQ | Fullstack-Team-as-a-Service for Your Business"
        ogDescription="Your secret weapon, white label development team. Like hiring an in-house team of designers & developers - at a fraction of the cost."
        ogUrl="https://fullstackhq.com"
      />
      <Header />
      <main className="home initialized-header">
        <TitleBanner
          title="Fullstack-Team-as-a-Service"
          title1="Fullstack Team as a Service"
          subtitle="Get immediate access to a battle-tested team of designers and developers on a pay-as-you-go monthly subscription."
        />
        <ServiceCard data={services} />
        <Brands
          text="Some of the brands we work with..."
          brands={logo}
        />
        <Testimonials
          title="Loved by world-class entrepreneurs &amp; tech companies"
          testimony={testimonyList}
        />
        <ContentImage content={cta} />
      </main>
      <Footer />
    </>
  )
}

export default index
