import React, { Component } from "react"

export default class TitleBanner extends Component {

  render() {
    const banner = this.props

    return (
      <div className="section-banner-wrapper">
        <div className="container">
          <h1 className="banner-title">{ banner.title }</h1>
          <h1 className="banner-title-sm">{ banner.title1 }</h1>
          <p className="banner-subtitle subheading">{ banner.subtitle }</p>
        </div>
      </div>
    )
  }
}
