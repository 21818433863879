import React from "react"
import { Link } from "gatsby"

function ServiceCard(props) {
  const service = props

  return (
    <div className="section-card-services">
      <div className="container">
        <div className="card-wrapper">
        { service.data.map((content, i) => {
          return (
            <Link key={ i } className={ content.type !== 'maintenance' ? "card-holder" :"card-holder card-maintenance" } to={ content.linkPath }>
              <div className="card card-default card-centered">
                { content.image ? <img src={ content.image } className={ content.type !== 'maintenance' ? "card-image-top" : "card-maintenance-image"} alt={ `${ content.title } Mascot` }/> : <></>}
                <div className="card-body">
                  { content.type !== 'maintenance' ? <h3 className="card-title">{ content.title }</h3> : <h3 className="card-title">{ content.title } <br/> { content.prefix }</h3>}
                  <p className="card-subtitle">{ content.subtitle }</p>
                  <p className="card-link">{ content.linkText } <img src={ require('../assets/images/arrow.png') } className="btn-arrow" alt="Learn More Icon" /></p>
                </div>
              </div>
            </Link>
          )
        })}
        </div>
      </div>
    </div>
  )
}

export default ServiceCard
